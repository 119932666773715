@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  margin: 0;
  padding: 0;
}
body{
  height: 100vh;
  background: #000;
  color: white;
  font-family: 'outfit';
  align-items: center;
}